function Navbar() {
    return (
      <nav className="sticky top-0 bg-green-600 text-white shadow-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center py-4 px-6">
          <a href="#home" className="text-lg font-bold">Arez Labs LLP</a>
          <div className="space-x-6">
            <a href="#about" className="hover:text-tangerine">About</a>
            <a href="#services" className="hover:text-tangerine">Services</a>
            <a href="#contact" className="hover:text-tangerine">Contact</a>
          </div>
        </div>
      </nav>
    );
  }
  
  export default Navbar;