import { useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { motion } from "framer-motion"; // For animations

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="min-h-screen flex flex-col bg-ivory text-gray-800">
      {/* Navbar */}
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

      {/* Main Content */}
      <main className="flex-grow overflow-auto">
        {/* Hero Section */}
        <motion.section
          id="home"
          className="bg-gradient-to-b from-green-600 to-teal-500 text-white h-screen flex items-center justify-center px-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="text-center">
            <img
              src="/logo.png" // Replace with the path to your actual logo
              alt="Arez Labs LLP Logo"
              className="mx-auto w-400 h-300 mb-8"
            />
            <h1 className="text-5xl font-extrabold tracking-tight sm:text-6xl">
              Empowering Businesses with{" "}
              <span className="text-tangerine">Innovation & Excellence</span>
            </h1>
            <p className="mt-6 text-lg sm:text-xl leading-relaxed">
              Welcome to Arez Labs LLP — a trusted partner for innovative software
              solutions and expert consulting services.
            </p>
            <a
              href="#about"
              className="mt-8 inline-block px-8 py-3 text-lg font-medium bg-tangerine text-green-900 rounded-lg shadow hover:bg-yellow-500 transition"
            >
              Learn More
            </a>
          </div>
        </motion.section>

        {/* About Section */}
        <motion.section
          id="about"
          className="bg-ivory h-screen flex items-center px-6"
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
        >
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-green-800">
              About <span className="text-tangerine">Arez Labs LLP</span>
            </h2>
            <p className="mt-6 text-lg leading-relaxed">
              At Arez Labs LLP, we don't just build software — we create solutions that
              transform businesses. With a foundation in cutting-edge technologies like
              AI, fintech, and import-export management systems, we empower companies to
              break barriers and scale new heights.
            </p>
            <p className="mt-4 text-lg leading-relaxed">
              Our expertise lies in blending innovative design with practical strategies
              to deliver systems that are not only efficient but also scalable and
              future-proof. Whether it’s optimizing your existing infrastructure,
              automating complex workflows, or developing tailored software solutions, we
              are your trusted partner in digital transformation.
            </p>
            <p className="mt-4 text-lg leading-relaxed">
              Trusted by industry leaders like Orgnyse PTY and Studiograph PTY, we pride
              ourselves on solving complex challenges with precision, creativity, and
              integrity.
            </p>
          </div>
        </motion.section>

        {/* Services Section */}
        <motion.section
          id="services"
          className="bg-gray-100 h-screen py-16 px-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 1 }}
        >
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold text-green-800">
                Our <span className="text-tangerine">Services</span>
              </h2>
              <p className="mt-4 text-lg leading-relaxed">
                Tailored solutions to meet your business needs and transform operations.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Fintech Solutions",
                  description:
                    "Building secure and innovative financial software to streamline operations.",
                },
                {
                  title: "AI-Driven Systems",
                  description:
                    "Leveraging artificial intelligence to solve complex challenges with ease.",
                },
                {
                  title: "Import-Export Management",
                  description:
                    "Automating processes with intelligent systems to boost efficiency.",
                },
                {
                  title: "IT Consulting",
                  description:
                    "Expert guidance on software architecture, design, and optimization.",
                },
                {
                  title: "HSN Classification System",
                  description:
                    "AI-powered systems for accurate, reliable classification in real-time.",
                },
                {
                  title: "Custom Software Development",
                  description:
                    "End-to-end solutions tailored to your unique business requirements.",
                },
              ].map((service, index) => (
                <div
                  key={index}
                  className="p-8 bg-white rounded-lg shadow-md hover:shadow-lg transition"
                >
                  <h3 className="text-xl font-bold text-green-700">{service.title}</h3>
                  <p className="mt-4 text-gray-700 leading-relaxed">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </motion.section>

        {/* Vision, Mission, Core Values Section */}
        <motion.section
          className="bg-gray-100 h-screen py-16 px-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <div className="max-w-6xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold text-green-800">
                Our <span className="text-tangerine">Vision & Mission</span>
              </h2>
              <p className="mt-4 text-lg leading-relaxed">
                Driving innovation and creating transformative software solutions.
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="p-8 bg-white rounded-lg shadow-md hover:shadow-lg transition">
                <h3 className="text-2xl font-bold text-green-800">Our Vision</h3>
                <p className="mt-4 text-gray-700">
                  To become a global leader in delivering transformative software and
                  consulting solutions, leveraging AI and domain expertise.
                </p>
              </div>
              <div className="p-8 bg-white rounded-lg shadow-md hover:shadow-lg transition">
                <h3 className="text-2xl font-bold text-green-800">Our Mission</h3>
                <ul className="mt-4 text-gray-700 list-disc list-inside space-y-2">
                  <li>
                    Develop innovative, reliable, and scalable software solutions that
                    solve real-world business problems.
                  </li>
                  <li>
                    Provide comprehensive consulting services, offering expertise in
                    software design, architecture, and performance optimization.
                  </li>
                  <li>
                    Leverage artificial intelligence and advanced technologies to simplify
                    complexities and deliver impactful solutions.
                  </li>
                </ul>
              </div>
              <div className="p-8 bg-white rounded-lg shadow-md hover:shadow-lg transition">
                <h3 className="text-2xl font-bold text-green-800">Core Values</h3>
                <ul className="mt-4 text-gray-700 list-disc list-inside space-y-2">
                  <li>Innovation: Driving creativity for complex challenges.</li>
                  <li>Excellence: Delivering top-tier services with precision.</li>
                  <li>Integrity: Upholding transparency and ethical practices.</li>
                </ul>
              </div>
            </div>
          </div>
        </motion.section>

        {/* Contact Section */}
        <motion.section
          id="contact"
          className="bg-white h-screen flex items-center px-6"
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
        >
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-green-800">
              Get in <span className="text-tangerine">Touch</span>
            </h2>
            <p className="mt-6 text-lg leading-relaxed">
              Let us guide your business to innovation and excellence.
            </p>
            <form className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-4 rounded-lg border focus:ring-2 focus:ring-green-500"
                required
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-4 rounded-lg border focus:ring-2 focus:ring-green-500"
                required
              />
              <textarea
                rows="4"
                placeholder="Your Message"
                className="col-span-2 w-full p-4 rounded-lg border focus:ring-2 focus:ring-green-500"
                required
              ></textarea>
              <button
                type="submit"
                className="col-span-2 w-full py-3 px-6 bg-green-600 text-white rounded-lg shadow hover:bg-green-700 transition"
              >
                Send Message
              </button>
            </form>
          </div>
        </motion.section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;